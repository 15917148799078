import Head from 'next/head'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react';
import Navbar from "../components/Layouts/Navbar";
import { homeNewsLetterList } from '../components/Common/DataSet/NewsLetterData';
import { blogList, ourServicesList, reportList } from '../components/Home/DataSet/MainData';
import axios from 'axios';
import TopBanner from '../components/Layouts/TopBanner';
import StripeSection from '../components/Home/StripeSection';

const Footer = dynamic(() => import('../components/Layouts/Footer', { ssr: false }));
const ReportSection = dynamic(() => import('../components/Home/ReportSection', { ssr: false }))
const NewsletterSection = dynamic(() => import('../components/Common/NewsLetterSection', { ssr: false }))
const BlogSection = dynamic(() => import('../components/Home/BlogSection', { ssr: false }))
const CaseStudySection = dynamic(() => import('../components/Home/CaseStudySection', { ssr: false }))
const OurServicesSection = dynamic(() => import('../components/Home/OurServicesSection', { ssr: false }));
const RelatedSection = dynamic(() => import('../components/Home/RelatedSection', { ssr: false }));


function App({ metaDetails, slug, deviceType }) {

  const [scrolled, setScrolled] = useState(0)
  const [scrolledStatus, setScrolledStatus] = useState(false)
  const [metaData, setMetaData] = useState(null);
  const [metaSlug, setmetaSlug] = useState(null);

  useEffect(() => {
    try {
      const complete_url = window.location.href;
      setmetaSlug(complete_url)
      setMetaData({
        meta_title: "Market Research Reports | Market Research Firm | DelveInsight",
        meta_description: "DelveInsight is a Healthcare Consulting & Market Research Firm. We advise Pharma companies in making strategic decisions.",
        meta_keywords: "market research companies, market research reports, market research firms, top market research companies, healthcare market research, pharma business consulting, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting, consulting services, business consulting, market trends."
      })
    } catch (err) {
    }
  }, [])



  useEffect(() => {
    const onScroll = () => setScrolled(window.pageYOffset);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);


  useEffect(() => {
    if (scrolled > 20) {
      setScrolledStatus(true)
    }
  }, [scrolled])

  return (
    <>
      <Head>
        <title>{(metaDetails != undefined) ? metaDetails.meta_title : (metaData) ? metaData?.meta_title : " Healthcare Market Research Reports & Consulting Firm | DelveInsight "}</title>
        <meta name="description" content={(metaDetails != undefined) ? metaDetails.meta_description : (metaData) ? metaData?.meta_description : "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies."} />
        <meta name="keywords" content={(metaDetails != undefined) ? metaDetails.meta_keywords : (metaData) ? metaData?.meta_keywords : "pharmaceutical business consulting, market research company, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting,  pharma consulting service, pharma leading, market trends."} />
        <meta property="og:title" content={(metaDetails != undefined) ? metaDetails.meta_title : (metaData) ? metaData?.meta_title : " Healthcare Market Research Reports & Consulting Firm | DelveInsight "} />
        <meta property="og:description" content={(metaDetails != undefined) ? metaDetails.meta_description : (metaData) ? metaData?.meta_description : "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies."} />
        <meta name="twitter:title" content={(metaDetails != undefined) ? metaDetails.meta_title : (metaData) ? metaData?.meta_title : " Healthcare Market Research Reports & Consulting Firm | DelveInsight "} />
        <meta name="twitter:description" content={(metaDetails != undefined) ? metaDetails.meta_description : (metaData) ? metaData?.meta_description : "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies."} />
        <meta property="og:url" content={(slug) ? slug : metaSlug} />
      </Head>

      <div className='w-full max-w-full'>
        <Navbar />
        <div>
          <div className="fixed top-0 left-0 z-50 w-full h-full" style={{
            pointerEvents: 'none', // Add this line
          }}>
          </div>
        </div>
        <TopBanner
          background={process.env.IS_SALE == 'active' ? process.env.IMG_CDN + "/img/home/png/desktop-sale-banner-blank.png" : process.env.IMG_CDN + "/img/home/png/home-page-banner.png"}
          mobileBackground={process.env.IS_SALE == 'active' ? process.env.IMG_CDN + "/img/home/png/mobile-sale-banner-blank.png" : process.env.IMG_CDN + "/img/home/png/home-page-banner.png"}
          device={deviceType}
          title={
            <>
              Healthcare Market Research Reports
            </>
          }
          description={
            <>
              Go through our vast portfolio of Pharmaceutical and MedTech reports.
            </>
          }
        />
      </div>
      <StripeSection />
      <ReportSection data={reportList} />
      {scrolledStatus &&
        <>
          <NewsletterSection data={homeNewsLetterList} />
          <BlogSection data={blogList} />
          <CaseStudySection />
          <OurServicesSection data={ourServicesList} />
          <RelatedSection />
          <Footer />
        </>
      }
    </>
  );
}

export default App;

export async function getStaticProps() {
  // const UA = req.headers['user-agent'];
  // const isMobile = Boolean(UA.match(
  //   /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  // ))
  const isMobile=false;



  let slug = '/';
  let full_slug = process.env.APP_URL + '/';
  if (slug.includes('/_next/')) {
    // checking if it making request json file then skip to fetch Meta Data
    return {
      props: {
        metaDetails: {
          meta_title: "Market Research Reports | Market Research Firm | DelveInsight",
          meta_description: "DelveInsight is a Healthcare Consulting & Market Research Firm. We advise Pharma companies in making strategic decisions.",
          meta_keywords: "market research companies, market research reports, market research firms, top market research companies, healthcare market research, pharma business consulting, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting, consulting services, business consulting, market trends."
        },
        slug: full_slug
      }
    };
  } else {
    // Remove the trailing slash, if any
    const cleanedSlug = slug.replace(/\/$/, '');
    try {
      const response = await axios.get(process.env.API_URL + '/meta/' + cleanedSlug);
      const metaDetails = response.data;

      if (metaDetails == undefined || metaDetails == '' || metaDetails == null || metaDetails?.code === 404) {
        return {
          props: {
            metaDetails: {
              meta_title: "Market Research Reports | Market Research Firm | DelveInsight",
              meta_description: "DelveInsight is a Healthcare Consulting & Market Research Firm. We advise Pharma companies in making strategic decisions.",
              meta_keywords: "market research companies, market research reports, market research firms, top market research companies, healthcare market research, pharma business consulting, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting, consulting services, business consulting, market trends."
            },
            slug: full_slug,
            deviceType: isMobile ? 'mobile' : 'desktop'
          }
        }
      } else {
        return {
          props: {
            metaDetails: metaDetails,
            slug: full_slug,
            deviceType: isMobile ? 'mobile' : 'desktop'
          },
        };

      }
    } catch (error) {
      // console.error('Error fetching API data:', error);
      return {
        props: {
          metaDetails: {
            meta_title: "Market Research Reports | Market Research Firm | DelveInsight",
            meta_description: "DelveInsight is a Healthcare Consulting & Market Research Firm. We advise Pharma companies in making strategic decisions.",
            meta_keywords: "market research companies, market research reports, market research firms, top market research companies, healthcare market research, pharma business consulting, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting, consulting services, business consulting, market trends."
          },
          slug: full_slug,
          deviceType: isMobile ? 'mobile' : 'desktop'
        },
      };
    }
  }
}

