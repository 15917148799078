const homeNewsLetterList = {
  title: "Looking for a long-term conference coverage partner?",
  buttonTitle: "Discuss with us",
  link: "/request-for-proposal",
};

let caseStudyNewsLetterList = {
  title: "Transform your business with our case study. Download now and achieve your goals!",
  buttonTitle: "Download",
  // link: "/request-for-proposal",
};

const rareDiseasesNewsLetterList = {
  title: "Want top-notch orphan drug consulting? Get in touch with our team",
  buttonTitle: "Connect Here",
  link: "/request-for-proposal",
};

const ciTrackingNewsLetterList = {
  title: "Want Top-notch CI Tracking Consulting? Get in touch with our team.",
  buttonTitle: "Connect Here",
  link: "/request-for-proposal",
};
const primaryResearchNewsLetterList = {
  title: "Want Top-Notch Primary Market Research Consulting? Get in touch with our team.",
  buttonTitle: "Connect Here",
  link: "/request-for-proposal",
};
const ascoNewsLetterList = {
  title: "We are available for your queries!",
  buttonTitle: "Ask Now",
  // link: "/request-for-proposal",
};
const covid19List = {
  title: "Hear from our CEO",
  buttonTitle: "Read Now",
  link: "/coronavirus-update-ceo-message"
}

export { 
    homeNewsLetterList, 
    caseStudyNewsLetterList, 
    rareDiseasesNewsLetterList,
    ciTrackingNewsLetterList,
    primaryResearchNewsLetterList,
    ascoNewsLetterList,
    covid19List
};
